import DotIcon from '@/components/atoms/Icon/svg/DotIcon'
import { makeStyles } from '@material-ui/styles'
import { LegendItem } from 'chart.js/auto'
import React from 'react'

const useStyle = makeStyles({
    root: {
        minWidth: 150,
        marginTop: 15,
        overflow: 'auto',
        '&::-webkit-scrollbar-track': {
            borderRadius: 10,
            border: '1px solid #B7B7B7',
        },
        '&::-webkit-scrollbar': {
            width: 10,
            backgroundColor: '#FFF',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#B7B7B7',
        },
    },
    allScope: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    legendItem: {
        padding: '5px 5px',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        fontSize: 14,
        fontWeight: 300,
    },
})

export const DefaultDoughnutLegend = ({
    height = 150,
    ...props
}: {
    legendItems: Array<LegendItem> | undefined
    allScope?: boolean
    height?: string | number
}) => {
    const { root, legendItem, allScope } = useStyle()
    return (
        <div className={`${root} ${props.allScope ? allScope : ''}`} style={{ height }}>
            {/* 
                // @ts-ignore */}
            {props.legendItems &&
                props.legendItems.map((item: LegendItem, index: number) => {
                    return (
                        <div className={legendItem} key={`legend-item-${item.text}-${index}`}>
                            <DotIcon color={item.fillStyle as string} />
                            <span className="text-clame-1">{item.text}</span>
                        </div>
                    )
                })}
        </div>
    )
}
