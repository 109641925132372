import { MonthSummaryItem } from '@/openapi'

export const checkLabel = (id?: number) => {
    switch (id) {
        case 1: {
            return { label: 'Scope1-輸送以外-燃料', id }
        }
        case 3: {
            return { label: 'Scope1-輸送以外-冷媒', id }
        }
        case 2: {
            return { label: 'Scope1-輸送以外-その他', id }
        }
        case 101: {
            return { label: 'Scope2-電気', id }
        }
        case 102: {
            return { label: 'Scope2-熱', id }
        }
        default: {
            return { label: 'Scope1-輸送', id: 0 }
        }
    }
}

export const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
        tooltipEl.style.borderRadius = '3px'
        tooltipEl.style.color = 'white'
        tooltipEl.style.opacity = 1
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.transform = 'translate(-50%, 0)'
        tooltipEl.style.transition = 'all .1s ease'

        const table = document.createElement('table')
        table.style.margin = '0px'

        tooltipEl.appendChild(table)
        chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
}

export const getHalfQuaterOptions = (startMonth: number) => {
    return {
        half: {
            first: Array.from({ length: 6 }, (_, i) => {
                const month: number = i + startMonth
                if (month > 12) return month - 12
                return month
            }),
            second: Array.from({ length: 6 }, (_, i) => {
                const month: number = i + startMonth + 6
                if (month > 12) return month - 12
                return month
            }),
        },
        quarter: {
            q1: Array.from({ length: 3 }, (_, i) => {
                const month: number = i + startMonth
                if (month > 12) return month - 12
                return month
            }),
            q2: Array.from({ length: 3 }, (_, i) => {
                const month: number = i + startMonth + 3
                if (month > 12) return month - 12
                return month
            }),
            q3: Array.from({ length: 3 }, (_, i) => {
                const month: number = i + startMonth + 6
                if (month > 12) return month - 12
                return month
            }),
            q4: Array.from({ length: 3 }, (_, i) => {
                const month: number = i + startMonth + 9
                if (month > 12) return month - 12
                return month
            }),
        },
    }
}

export const filterDataByFinancialYear = (
    array: MonthSummaryItem[],
    startMonth: number,
    analysisYear: number,
): MonthSummaryItem[] => {
    const result = array.filter((m) => {
        const timeArr = m.month.split('-')
        const month = Number(timeArr[1])
        const year = Number(timeArr[0])

        if (startMonth && year === analysisYear && month >= startMonth) return true
        if (startMonth && year === analysisYear + 1 && month < startMonth) return true
        return false
    })
    return result
}
