import ArrowClockwiseIcon from '@/components/atoms/Icon/svg/ArrowClockwiseIcon'
import CaretDownIcon from '@/components/atoms/Icon/svg/CaretDown'
import ExpandIcon from '@/components/atoms/Icon/svg/ExpandIcon'
import { SiteSummaryItem } from '@/openapi'
import theme from '@/theme'
import {
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { TSortMode } from './AnalysisTable'

const useStyle = makeStyles({
    container: {
        padding: 10,
        border: `1px solid ${theme.colors.border}`,
    },
    tableWrapper: {
        width: 408,
        minWidth: 408,
        maxWidth: 800,
        overflow: 'auto',
    },
    tableHead: {
        color: theme.colors.white,
        fontSize: 14,
        fontWeight: 600,
        textAlign: 'center',
        backgroundColor: '#6E6E6E',
        position: 'sticky',
        top: 0,
        borderRight: `3px solid ${theme.colors.white}`,
    },
    firstCol: {
        fontSize: 14,
        fontWeight: 300,
        backgroundColor: '#FDF3DF',
        borderTop: `3px solid #fff`,
        borderRight: `3px solid #fff`,
    },
    showMore: {
        marginTop: 10,
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
        textDecoration: 'underline',
        '& > svg': {
            transform: 'scale(0.8)',
        },
    },
    other: {
        backgroundColor: '#DDDDDD',
    },
})

interface TSiteInfoTable {
    siteData: SiteSummaryItem[]
    scope?: 1 | 2 | 3
}

function SiteInfoTable(props: TSiteInfoTable) {
    const [limit, setLimit] = useState(9)
    const [sortMode, setSortMode] = useState<TSortMode>('default')
    const classes = useStyle()
    const headers = [
        { name: '拠点', width: 184 },
        { name: '排出量（単位：t-CO₂e）', width: 200 },
    ]

    const handleSort = () => {
        if (['default', 'desc'].includes(sortMode)) {
            setSortMode('asc')
        }
        if (sortMode === 'asc') {
            setSortMode('desc')
        }
    }
    const genData = () => {
        let data = props.siteData.sort((a, b) => b.quantity - a.quantity)
        if (props.scope) {
            data = data.map((d) => ({
                ...d,
                quantity: d.scopes.find((s) => s.id === props.scope)?.quantity || 0,
            }))
        }
        return data
    }

    const showOther = props.siteData.length > limit
    const resetShow = limit > 9

    const data = useMemo(() => {
        const currData = genData()
        const otherDataQuantity = currData.slice(limit).reduce((a, b) => a + b.quantity, 0)
        const siteData = currData.slice(0, limit)
        if (showOther)
            siteData.push({
                id: currData[currData.length - 1].id + 1,
                name: 'その他',
                scopes: [],
                quantity: otherDataQuantity,
            })

        if (sortMode === 'asc') siteData.sort((a, b) => a.quantity - b.quantity)
        if (sortMode === 'desc') siteData.sort((a, b) => b.quantity - a.quantity)
        return siteData
    }, [sortMode, limit])

    return (
        <div className={classes.container}>
            <TableContainer className={classes.tableWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((h, i) => (
                                <TableCell key={h.name} width={h.width} className={classes.tableHead}>
                                    <div>{h.name}</div>
                                    {i === 1 && (
                                        <IconButton style={{ padding: 0 }} onClick={() => handleSort()}>
                                            <CaretDownIcon direction={sortMode === 'asc' ? 'up' : 'down'} />
                                        </IconButton>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((b, i) => (
                            <TableRow key={i}>
                                <TableCell
                                    className={`${classes.firstCol} ${b.name === 'その他' ? classes.other : ''}`}
                                >
                                    {b.name}
                                </TableCell>
                                <TableCell align="right">{b.quantity ? b.quantity.toFixed(3) : b.quantity}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {showOther && (
                <div onClick={() => setLimit((pre) => pre + 10)} className={classes.showMore}>
                    次の10件を表示する <ExpandIcon direction="down" />
                </div>
            )}
            {resetShow && (
                <div onClick={() => setLimit(9)} className={classes.showMore}>
                    <ArrowClockwiseIcon /> 最初の表示に戻す
                </div>
            )}
        </div>
    )
}

export default SiteInfoTable
