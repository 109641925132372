import theme from '@/theme'
import { getOrCreateTooltip } from '@/utils/chartHelper'
import { round, sum } from '@/utils/math'
import { thousandSeparator } from '@/utils/numHelper'
import { makeStyles } from '@material-ui/core'
import { Chart, ChartDataset, DoughnutDataPoint, LegendItem } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useCallback, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { ChartProps } from 'react-chartjs-2/dist/types'
import { GettingChartDataMessage, valuesToPercentages } from '..'
import { DefaultDoughnutLegend } from './legend'

const externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || []
        const bodyLines = tooltip.body.map((b: any) => b.lines)

        const tableHead = document.createElement('thead')

        titleLines.forEach((title: any) => {
            const tr = document.createElement('tr')
            tr.style.borderWidth = '0px'

            const th = document.createElement('th')
            th.style.borderWidth = '0px'
            const text = document.createTextNode(title)

            th.appendChild(text)
            tr.appendChild(th)
            tableHead.appendChild(tr)
        })

        const tableBody = document.createElement('tbody')
        bodyLines.forEach((body: any, i: any) => {
            const colors = tooltip.labelColors[i]

            const span = document.createElement('span')
            span.style.background = colors.backgroundColor
            span.style.borderColor = colors.borderColor
            span.style.borderWidth = '2px'
            span.style.marginRight = '10px'
            span.style.height = '10px'
            span.style.width = '10px'
            span.style.display = 'inline-block'

            const tr = document.createElement('tr')
            tr.style.backgroundColor = 'inherit'
            tr.style.borderWidth = '0px'

            const td = document.createElement('td')
            td.style.borderWidth = '0px'

            const text = document.createTextNode(`${body}%`)

            td.appendChild(span)
            td.appendChild(text)
            tr.appendChild(td)
            tableBody.appendChild(tr)
        })

        const tableRoot = tooltipEl.querySelector('table')

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove()
        }

        // Add new children
        tableRoot.appendChild(tableHead)
        tableRoot.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
}

export type GhgDoughnutDataset = Pick<
    ChartDataset<'doughnut', Array<DoughnutDataPoint>>,
    'label' | 'data' | 'backgroundColor'
>
export type GhgDoughnutProps = Omit<ChartProps, 'type' | 'data'> & {
    dataset?: GhgDoughnutDataset
    labels?: Array<string>
    getLegendItems?: (chart: Chart) => void
    noLegend?: boolean
    noLabels?: boolean
}

const defaults: { dataset: any; options: any } = {
    dataset: {
        borderWidth: 3,
        hoverOffset: 0,
    },
    options: {
        cutout: '55%',
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                formatter: (value: number) => {
                    if (value >= 1) return value + '%'
                    else return ''
                },
                font: {
                    size: 12,
                    weight: 600,
                },
                color: theme.colors.white,
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler,
            },
        },
        aspectRatio: 1,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
            height: 200,
            width: 200,
        },
    },
}

/**
 * Doughnutの真ん中にテキストを描く
 *
 * 参考
 * GitHub Issue: https://github.com/reactchartjs/react-chartjs-2/issues/383
 * ChartJS docs: https://www.chartjs.org/docs/latest/api/interfaces/Plugin.html
 */
const DoughnutHoleTextPluginFactory = (text: string) => ({
    id: 'doughnut-hole-text-plugin',
    afterDraw: (chart: Chart) => {
        const fontSize = Number((chart.chartArea.height * 0.07).toFixed(2))
        chart.ctx.font = `${fontSize}px ${theme.typography.fontFamily}`
        chart.ctx.textBaseline = 'bottom'
        chart.ctx.textAlign = 'center'
        chart.ctx.fillText(text, chart.width / 2, (chart.chartArea.height + fontSize) / 2)
    },
    beforeDraw: (chart: Chart) => {
        const fontSize = Number((chart.chartArea.height * 0.05).toFixed(2))
        chart.ctx.font = `${fontSize}px ${theme.typography.fontFamily}`
        chart.ctx.textBaseline = 'bottom'
        chart.ctx.textAlign = 'center'
        chart.ctx.fillText('t-CO₂e', chart.width / 2, (chart.chartArea.height + fontSize) / 2 + 20)
    },
})
const useStyles = makeStyles({
    containerChart: {
        width: '200px',
    },
    dflex: {
        display: 'flex',
    },
})

const GhgDoughnut = (props: GhgDoughnutProps) => {
    // 凡例項目を抽出するためのcallback
    // https://github.com/reactchartjs/react-chartjs-2/issues/81#issuecomment-546125187
    const [legendItems, setLegendItems] = useState<Array<LegendItem>>()
    const doughnutRef = useCallback((chart: Chart) => {
        if (chart) {
            //@ts-ignore
            setLegendItems(chart.legend.legendItems)
        }
    }, [])
    const classes = useStyles()
    if (props.dataset) {
        const dataset = {
            ...props.dataset,
            data: valuesToPercentages(props.dataset.data),
        }
        const total = sum(props.dataset.data)
        const result = total !== 0 ? total.toFixed(3) : total.toString()
        const text = thousandSeparator(result)

        const plugins: Array<any> = [DoughnutHoleTextPluginFactory(text)]

        if (!props.noLabels) plugins.push(ChartDataLabels)

        return (
            <>
                <div className={classes.containerChart}>
                    <Doughnut
                        //@ts-ignore
                        ref={props.noLegend ? props.getLegendItems : doughnutRef}
                        type="Doughnut"
                        data={{
                            labels: props.labels,
                            datasets: [
                                {
                                    ...defaults.dataset,
                                    ...dataset,
                                },
                            ],
                        }}
                        options={{ ...defaults.options, ...props.options }}
                        plugins={plugins}
                    ></Doughnut>
                </div>
                {!props.noLegend && <DefaultDoughnutLegend legendItems={legendItems} />}
            </>
        )
    } else {
        return <GettingChartDataMessage />
    }
}

export default GhgDoughnut
