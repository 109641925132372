import { Objective } from '@/openapi'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'

interface PdfHeader {
    subTitle: ReactNode | ReactNode[]
    title: string
    objective: Objective
}

const useStyle = makeStyles({
    container: {
        color: theme.colors.primaryN,
        maxWidth: theme.contentContainer.width,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 86,
        boxShadow: 'rgb(33 35 38 / 10%) 0px 10px 10px -10px',
        '& .sub-title': {
            fontSize: 22,
            fontWeight: 600,
        },
        '& .company-name': {
            fontSize: 18,
            fontWeight: 300,
        },
    },
    content: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: 15,
        minHeight: 108,
        padding: '0 40px',
        backgroundColor: theme.colors.expandBackground,
        '& .title': {
            fontSize: 22,
            fontWeight: 600,
        },
        '& .objective': {
            fontSize: 16,
            fontWeight: 300,
        },
    },
})

function PdfHeader(props: PdfHeader) {
    const { storeState } = useStore()
    const classes = useStyle()
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className="sub-title">{props.subTitle}</div>
                <div className="company-name">{storeState.organization?.name || ''}</div>
            </div>
            <div className={classes.content}>
                <div className="title">{props.title}</div>
                <div className="objective">
                    {props.objective.year} 年度 目標：{props.objective.text}
                </div>
            </div>
        </div>
    )
}

export default PdfHeader
