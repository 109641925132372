import { BarData, GhgChart } from '@/components/charts'
import { GhgDoughnutProps } from '@/components/charts/Doughnut'
import { GhgBarDataset, GhgStackedBarProps } from '@/components/charts/StackedBar'
import { AllOrganizationSiteSummary } from '@/components/template/Analysis/Overview/Scopes1and2'
import { DMode, Mode } from '@/hooks/useAnalysis'
import {
    AnnualOrganizationSummary,
    AnnualSiteSummary,
    Category,
    EmissionFactorSummaryItem,
    EmissionFactorTableNode,
    EmissionFactorTableSummaryItem,
    HalfYearOrganizationSummary,
    MonthlyOrganizationSummary,
    MonthlySiteSummary,
    MonthSummaryItem,
    QuarterlyOrganizationSummary,
    ScopeSummaryItem,
    SiteSummaryItem,
    YearSummaryItem,
} from '@/openapi/api'
import { CompareResponseData } from '@/pages/analysis/organization'
import { CompareResponseSiteData } from '@/pages/analysis/sites'
import { uniqForObject } from '@/utils/arrayHelper'
import { checkLabel, getHalfQuaterOptions } from '@/utils/chartHelper'
import { round, sum, sumOfMatrix } from '@/utils/math'
import { Summary } from '..'

namespace ResponseToChartData {
    // =================================================================================
    // Helper関数
    // =================================================================================
    const findByMonth = function (
        monthSummaries: Array<Summary.HasMonth>,
        monthNum: number,
    ): Summary.HasMonth | undefined {
        return monthSummaries.find((month) => month.month.split('-')[1] == `${String(monthNum).padStart(2, '0')}`)
    }

    // https://stackoverflow.com/questions/3803331/how-can-i-modulo-when-my-numbers-start-from-1-not-zero
    const moduloFromOne = function (num: number, max = 12): number {
        return ((num - 1) % max) + 1
    }

    // =================================================================================
    // 棒グラフ
    // =================================================================================
    export function Bar(monthStart?: number, mode?: Mode, value?: number | null) {
        const startMonth = monthStart || 1

        const handleData = (data: { [key: string]: Array<number> }) => {
            switch (mode) {
                case 'half': {
                    const newData: { [key: string]: Array<number> } = {}
                    if (value === 1) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = values.slice(0, 6)
                        }
                        return newData
                    }
                    if (value === 2)
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = values.slice(6)
                        }
                    return newData
                }
                case 'quarter': {
                    const newData: { [key: string]: Array<number> } = {}
                    if (value === 1) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = values.slice(0, 3)
                        }
                        return newData
                    }
                    if (value === 2) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = values.slice(3, 6)
                        }
                        return newData
                    }
                    if (value === 3) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = values.slice(6, 9)
                        }
                        return newData
                    }
                    if (value === 4) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = values.slice(9)
                        }
                        return newData
                    }
                }
                default: {
                    return data
                }
            }
        }
        const handleDataCategory = (data: { [key: string]: { data: Array<number>; id: number } }) => {
            switch (mode) {
                case 'half': {
                    const newData: { [key: string]: { data: Array<number>; id: number } } = {}
                    if (value === 1) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = { id: values.id, data: values.data.slice(0, 6) }
                        }
                        return newData
                    }
                    if (value === 2)
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = { id: values.id, data: values.data.slice(6) }
                        }
                    return newData
                }
                case 'quarter': {
                    const newData: { [key: string]: { data: Array<number>; id: number } } = {}
                    if (value === 1) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = { id: values.id, data: values.data.slice(0, 3) }
                        }
                        return newData
                    }
                    if (value === 2) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = { id: values.id, data: values.data.slice(3, 6) }
                        }
                        return newData
                    }
                    if (value === 3) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = { id: values.id, data: values.data.slice(6, 9) }
                        }
                        return newData
                    }
                    if (value === 4) {
                        for (const [key, values] of Object.entries(data)) {
                            newData[key] = { id: values.id, data: values.data.slice(9) }
                        }
                        return newData
                    }
                }
                default: {
                    return data
                }
            }
        }

        const scopes = function (
            resData: AnnualOrganizationSummary | AnnualSiteSummary,
            scopeNumbers = [1, 2, 3],
            stack: string = 'hogehoge',
        ): GhgStackedBarProps['datasets'] {
            let scopeData = {
                1: new Array(12).fill(0),
                2: new Array(12).fill(0),
                3: new Array(12).fill(0),
            }

            for (let i = startMonth; i < 12 + startMonth; i++) {
                const monthlySummary = findByMonth(resData.months, moduloFromOne(i)) as Summary.Monthly
                if (monthlySummary) {
                    for (const [scopeNumber, values] of Object.entries(scopeData)) {
                        values[i - startMonth] =
                            monthlySummary.scopes.find((s) => s.id === Number(scopeNumber))?.quantity ?? 0
                    }
                }
            }
            const newScopeData = handleData(scopeData)
            const dataset = []
            for (const [scopeNumber, values] of Object.entries(newScopeData)) {
                if (scopeNumbers.includes(Number(scopeNumber))) {
                    dataset.unshift({
                        label: `Scope ${scopeNumber}`,
                        data: values,
                        backgroundColor: GhgChart.BackgroundColors.allScopes[Number(scopeNumber) - 1],
                        stack: stack,
                    })
                }
            }

            return dataset
        }

        const scopesCompare = function (
            resData: AnnualOrganizationSummary | AnnualSiteSummary,
            scopeNumber: number,
            currentYear: number,
            emissionFactorTableNodes: EmissionFactorTableNode[],
            stack: string = 'hogehoge',
        ): GhgStackedBarProps['datasets'] {
            const scopesObj: { [key: string]: number[] } = {}

            const emissionFactors: EmissionFactorTableSummaryItem[] = []
            resData.months.forEach((month) =>
                month.scopes
                    .find((scope) => scope.id === scopeNumber)
                    ?.categories[0].highestEmissionFactorTables?.forEach((eF) => emissionFactors.push(eF)),
            )

            const replaceEmissionFactor: EmissionFactorTableSummaryItem[] = []
            emissionFactors.forEach((eF) => {
                if (eF) {
                    const index = replaceEmissionFactor.findIndex(
                        (ref) =>
                            ref.id === eF.id && ref.categoryEmissionFactorTableId === eF.categoryEmissionFactorTableId,
                    )
                    if (index === -1) {
                        replaceEmissionFactor.push(eF)
                    } else {
                        replaceEmissionFactor.splice(index, 1, {
                            ...eF,
                            quantity: replaceEmissionFactor[index].quantity + eF.quantity,
                        })
                    }
                }
            })

            replaceEmissionFactor.forEach((eF) => {
                if (eF) {
                    const index = emissionFactorTableNodes.findIndex(
                        (efN) => efN.id === eF.categoryEmissionFactorTableId && efN.emissionFactorTableId === eF.id,
                    )
                    const node = emissionFactorTableNodes.find(
                        (efN) => efN.id === eF.categoryEmissionFactorTableId && efN.emissionFactorTableId === eF.id,
                    )
                    const name = node?.parent ? `${node.parent} > ${node.name}` : node?.name
                    if (name) scopesObj[`${name}--${index}`] = new Array(12).fill(0)
                }
            })

            for (let i = startMonth; i < 12 + startMonth; i++) {
                const monthlySummary = findByMonth(resData.months, moduloFromOne(i)) as Summary.Monthly
                if (monthlySummary) {
                    for (const [emmisionFactorName, values] of Object.entries(scopesObj)) {
                        const emissionFactors = monthlySummary.scopes
                            .find((s) => s.id === scopeNumber)
                            ?.categories.map((c) => c.highestEmissionFactorTables)
                            .filter(Boolean)
                            .flat()
                            .find((eF) => {
                                const node = emissionFactorTableNodes.find(
                                    (efN) =>
                                        efN.id === eF.categoryEmissionFactorTableId &&
                                        efN.emissionFactorTableId === eF.id,
                                )
                                const name = node?.parent ? `${node.parent} > ${node.name}` : node?.name
                                return name === emmisionFactorName.split('--')[0]
                            })
                        values[i - startMonth] = emissionFactors?.quantity ?? 0
                    }
                }
            }
            const genBg = (i: number) => {
                if (stack !== 'hogehoge') {
                    if (scopeNumber === 1) return GhgChart.BackgroundColors.scope1Categories[i]
                    return GhgChart.BackgroundColors.scope2Categories[i]
                } else {
                    if (scopeNumber === 1) return GhgChart.BackgroundColors.scope1CategoriesCompare[i]
                    return GhgChart.BackgroundColors.scope2CategoriesCompare[i]
                }
            }
            const newScopeData = handleData(scopesObj)
            const dataset: GhgStackedBarProps['datasets'] = []
            const dataSorter = []

            const categoryNodes: Array<EmissionFactorTableNode & { nameWithParent: string }> = []
            emissionFactorTableNodes.forEach((node, i) => {
                if (node.categoryId === scopeNumber) {
                    const name = node?.parent ? `${node.parent} > ${node.name}` : node?.name
                    categoryNodes.push({ ...node, nameWithParent: name })
                }
            })
            for (const [emmisionFactorName, values] of Object.entries(newScopeData)) {
                const split = emmisionFactorName.split('--')
                const index = +split[1]
                const label = split[0]
                const bgIndex = categoryNodes.findIndex((c) => c.nameWithParent === label)
                dataSorter.push({
                    index,
                    bgIndex,
                    label: `${label} ${resData.year !== currentYear ? 'isCompare' : ''}`,
                    data: values,
                    stack: stack,
                })
            }
            dataSorter
                .sort((a, b) => a.index - b.index)
                .forEach(({ index, ...data }, i) => {
                    dataset.unshift({ ...data, backgroundColor: genBg(data.bgIndex) })
                })
            return dataset
        }

        const scope1or2 = function (
            resData: AnnualOrganizationSummary | AnnualSiteSummary,
            scopeNumbers: number,
            emissionFactorTableNodes: EmissionFactorTableNode[],
            stack: string = 'hogehoge',
        ): GhgStackedBarProps['datasets'] {
            let scopeData: { [key: string]: number[] } = {}
            let scopeInfo: (EmissionFactorTableSummaryItem & { data: number[] })[] = []
            resData.scopes
                .find((s) => s.id === scopeNumbers)
                ?.categories.map((c) => c.highestEmissionFactorTables)
                .flat()
                .forEach((item) => {
                    if (item) scopeInfo.push({ ...item, data: [] })
                })

            const scopesData = resData.months.map((d) => ({
                month: +d.month.split('-')[1],
                data: d.scopes
                    .find((d) => d.id === scopeNumbers)
                    ?.categories.map((d) => d.highestEmissionFactorTables)
                    .flat(),
            }))

            scopeInfo.forEach((s, i) => {
                const data: number[] = []
                const node = emissionFactorTableNodes.find((node) => node.id === s.categoryEmissionFactorTableId)
                const index = emissionFactorTableNodes.findIndex((node) => node.id === s.categoryEmissionFactorTableId)
                for (let i = startMonth; i < 12 + startMonth; i++) {
                    const month = i > 12 ? i - 12 : i
                    const found = scopesData.find((f) => f.month === month)
                    if (found) {
                        const quantity = found?.data?.find(
                            (d) =>
                                d?.id === s.id && d.categoryEmissionFactorTableId === s.categoryEmissionFactorTableId,
                        )?.quantity
                        data.push(quantity || 0)
                    } else {
                        data.push(0)
                    }
                }
                if (node) scopeData[`${node?.parent ? `${node.parent} > ` : ''} ${node?.name || ''}-${index}`] = data
            })
            const newScopeData = handleData(scopeData)

            const dataset: GhgStackedBarProps['datasets'] = []
            const datasetSorter = []
            for (const [key, values] of Object.entries(newScopeData)) {
                const label = key.split('-')[0]
                const id = +key.split('-')[1]
                datasetSorter.push({
                    id,
                    label: label,
                    data: values,
                    backgroundColor: '',
                    stack: stack,
                })
            }
            const scopeBgArr =
                scopeNumbers === 1
                    ? GhgChart.BackgroundColors.scope1Categories
                    : GhgChart.BackgroundColors.scope2Categories
            datasetSorter
                .sort((a, b) => a.id - b.id)
                .forEach(({ id, ...d }, i) => {
                    dataset.unshift({
                        ...d,
                        backgroundColor: scopeBgArr[i],
                    })
                })
            return dataset
        }

        const scope1or2Detail = function (
            allData: MonthSummaryItem[],
            scopeNumbers: number,
            analysisYear: number,
            emissionFactorTableId: number,
            categoryEmissionFactorTableId: number,
            stack: string = 'hogehoge',
        ): GhgStackedBarProps['datasets'] {
            type TEmissionFactorInfo = EmissionFactorSummaryItem & { data: number[] }
            type ScopeData = { [key: string]: number[] }
            let scopeData: ScopeData = {}
            const emissionFactorInfo: TEmissionFactorInfo[] = []

            const scopesData = allData.map((d) => {
                const data = d.scopes
                    .find((d) => d.id === scopeNumbers)
                    ?.categories.map((d) => d.emissionFactorTables)
                    .flat()
                    .find(
                        (eF) =>
                            eF &&
                            eF.id === emissionFactorTableId &&
                            eF.categoryEmissionFactorTableId === categoryEmissionFactorTableId,
                    )?.emissionFactors
                return {
                    year: +d.month.split('-')[0],
                    month: +d.month.split('-')[1],
                    data,
                }
            })
            scopesData.forEach((d) => {
                if (d.data) {
                    d.data.forEach((innerData) => {
                        const index = emissionFactorInfo.findIndex((eF) => eF.id === innerData.id)
                        if (index === -1) {
                            emissionFactorInfo.push({ ...innerData, data: [] } as TEmissionFactorInfo)
                        } else {
                            const eFItem = emissionFactorInfo[index]
                            emissionFactorInfo[index] = { ...eFItem, quantity: eFItem.quantity + innerData.quantity }
                        }
                    })
                }
            })

            const emissionFactors = emissionFactorInfo.filter((eF) => eF.quantity).sort((a, b) => a.id - b.id)

            emissionFactors.forEach((s, i) => {
                const data: number[] = []
                for (let i = startMonth; i < 12 + startMonth; i++) {
                    const month = i > 12 ? i - 12 : i
                    const year = i > 12 ? analysisYear + 1 : analysisYear
                    const found = scopesData.find((f) => f.month === month && f.year === year)
                    if (found) {
                        const quantity = found?.data?.find((d) => d?.id === s.id)?.quantity
                        data.push(quantity || 0)
                    } else {
                        data.push(0)
                    }
                }
                scopeData[`${s.name}--${i}`] = data
            })

            const newScopeData: ScopeData = {}
            Object.entries(handleData(scopeData)).map((s) => {
                const name: string = s[0].split('--')[0]
                if (newScopeData[name]) {
                    newScopeData[name] = sumOfMatrix([newScopeData[name], s[1]])
                } else {
                    newScopeData[name] = s[1]
                }
            })

            const dataset = []
            const backgroundColor = (index: number) =>
                scopeNumbers === 1
                    ? GhgChart.BackgroundColors.scope1CategoriesDetail[index]
                    : GhgChart.BackgroundColors.scope2CategoriesDetail[index]
            const otherData: BarData = {
                label: 'その他',
                data: Array.from({ length: 12 }, () => 0),
                backgroundColor: backgroundColor(9),
                stack,
            }
            let i = 0
            for (const [key, values] of Object.entries(newScopeData)) {
                if (i > 8) {
                    otherData.data = sumOfMatrix([otherData.data, values])
                } else {
                    const label = key.split('--')[0]
                    dataset.unshift({
                        label: label,
                        data: values,
                        backgroundColor: backgroundColor(i),
                        stack: stack,
                    })
                }
                i++
            }
            const showOther = Boolean(otherData.data.reduce((a, b) => a + b, 0))
            if (showOther) dataset.unshift(otherData)
            return dataset
        }

        const compare = function (resData: Array<CompareResponseData | CompareResponseSiteData>): Array<GhgBarDataset> {
            let scopeData = {
                1: new Array(),
                2: new Array(),
                3: new Array(),
                4: new Array(),
                5: new Array(),
                6: new Array(),
            }

            let arrStart = resData[0].months as Array<MonthlySiteSummary | MonthlyOrganizationSummary>
            let arrEnd = resData[1].months as Array<MonthlySiteSummary | MonthlyOrganizationSummary>
            for (let i = startMonth; i < 12 + startMonth; i++) {
                const dataEnd = arrEnd.find((s) => {
                    const month = Number(s.month.split('-')[1])
                    return month === moduloFromOne(i)
                })
                if (dataEnd && dataEnd.month) {
                    const scope1 = dataEnd.scopes.find((scope) => scope.id == 1)
                    const scope2 = dataEnd.scopes.find((scope) => scope.id == 2)
                    const scope3 = dataEnd.scopes.find((scope) => scope.id == 3)
                    scopeData[1].push(scope1?.quantity ?? 0)
                    scopeData[2].push(scope2?.quantity ?? 0)
                    scopeData[3].push(scope3?.quantity ?? 0)
                } else {
                    scopeData[1].push(0)
                    scopeData[2].push(0)
                    scopeData[3].push(0)
                }
                const dataStart =
                    arrStart.length > 0 &&
                    arrStart.find((s: MonthlySiteSummary | MonthlyOrganizationSummary) => {
                        const month = Number(s.month.split('-')[1])
                        const year = Number(s.month.split('-')[0])
                        return month == moduloFromOne(i)
                    })
                if (dataStart && dataStart.month) {
                    const scope1 = dataStart.scopes.find((scope) => scope.id == 1)
                    const scope2 = dataStart.scopes.find((scope) => scope.id == 2)
                    const scope3 = dataStart.scopes.find((scope) => scope.id == 3)
                    scopeData[4].push(scope1?.quantity ?? 0)
                    scopeData[5].push(scope2?.quantity ?? 0)
                    scopeData[6].push(scope3?.quantity ?? 0)
                } else {
                    scopeData[4].push(0)
                    scopeData[5].push(0)
                    scopeData[6].push(0)
                }
            }
            const dataset: Array<GhgBarDataset> = []
            const newScopeData = handleData(scopeData)
            for (const [index, values] of Object.entries(newScopeData)) {
                dataset[Number(index) - 1] = {
                    label: Number(index) > 3 ? `Scope ${Number(index) - 3}` : `Scope ${index} isCompare`,
                    data: values,
                    backgroundColor:
                        Number(index) > 3
                            ? GhgChart.BackgroundColors.allScopes[Number(index) - 4]
                            : GhgChart.BackgroundColors.allScopesCompare[Number(index) - 1],
                    stack: Number(index) > 3 ? 'hogehoge 1' : 'hogehoge',
                }
            }
            const newDataset = [...dataset.slice(0, 3).reverse(), ...dataset.slice(3).reverse()]
            return newDataset
        }

        const scope3Categories = function (
            resData: AnnualOrganizationSummary | AnnualSiteSummary,
            currentYear: number,
            isCompare: boolean = false,
            stack: string = 'hogehoge',
        ): GhgStackedBarProps['datasets'] {
            let categoriesData: { [key: string]: { data: Array<number>; id: Category['id'] } } = {}

            for (let i = startMonth; i < 12 + startMonth; i++) {
                const scope3Month = (findByMonth(resData.months, moduloFromOne(i)) as Summary.Monthly)?.scopes.find(
                    (s) => s.id == 3,
                )

                const monthlyCategories = scope3Month?.categories
                const monthlyTotal = scope3Month?.quantity

                if (monthlyCategories && monthlyTotal) {
                    for (const category of monthlyCategories) {
                        const key = `${category.id}-${category.name}`
                        if (!categoriesData[key]) categoriesData[key] = { data: new Array(12).fill(0), id: category.id }
                        categoriesData[key].data[i - startMonth] = category.quantity
                    }
                }
            }

            const newCategoriesData = Object.entries(handleDataCategory(categoriesData)).sort((a, b) => {
                const x = +a[0].split('-')[0] as number
                const y = +b[0].split('-')[0] as number
                return x < y ? -1 : x > y ? 1 : 0
            })
            const dataset = []
            for (const [categoryName, data] of newCategoriesData) {
                dataset.unshift({
                    label: `${data.id - 2}. ${categoryName.split('：')[1]} ${
                        resData.year !== currentYear ? 'isCompare' : ''
                    }`,
                    data: data.data,
                    backgroundColor: isCompare
                        ? GhgChart.BackgroundColors.scope3CategoriesCompare[data.id - 3]
                        : GhgChart.BackgroundColors.scope3Categories[data.id - 3],
                    stack: stack,
                })
            }
            return dataset
        }

        const scope3CategoriesDetails = function (
            allData: MonthSummaryItem[],
            emissionFactorTableId: number,
            analysisYear: number,
            stack: string = 'hogehoge',
        ): GhgStackedBarProps['datasets'] {
            type TEmissionFactorInfo = EmissionFactorSummaryItem & { data: number[] }
            let scopeData: { [key: string]: number[] } = {}
            const emissionFactorInfo: TEmissionFactorInfo[] = []
            const scopeNumbers = 3
            const scopesData = allData.map((d) => {
                const data = d.scopes
                    .find((d) => d.id === scopeNumbers)
                    ?.categories.find((category) => category.id === emissionFactorTableId)
                    ?.emissionFactorTables?.map((eF) => eF.emissionFactors)
                    .flat() as EmissionFactorSummaryItem[] | undefined
                return {
                    year: +d.month.split('-')[0],
                    month: +d.month.split('-')[1],
                    data,
                }
            })

            scopesData.forEach((d) => {
                if (d.data) {
                    d.data.forEach((innerData) => {
                        const index = emissionFactorInfo.findIndex((eF) => eF.id === innerData.id)
                        if (index === -1) {
                            emissionFactorInfo.push({ ...innerData, data: [] } as TEmissionFactorInfo)
                        } else {
                            emissionFactorInfo[index].quantity += innerData.quantity
                        }
                    })
                }
            })
            emissionFactorInfo
                .sort((a, b) => a.id - b.id)
                .filter((eF) => eF.quantity)
                .forEach((s, i) => {
                    const data: number[] = []
                    for (let i = startMonth; i < 12 + startMonth; i++) {
                        const month = i > 12 ? i - 12 : i
                        const year = i > 12 ? analysisYear + 1 : analysisYear
                        const found = scopesData.find((f) => f.month === month && f.year === year)
                        if (found) {
                            const quantity = found?.data?.find((d) => d?.id === s.id)?.quantity
                            data.push(quantity || 0)
                        } else {
                            data.push(0)
                        }
                    }
                    scopeData[`${s.name}--${i}`] = data
                })

            const newScopeData = handleData(scopeData)
            const dataset = []
            const backgroundColor = (index: number) =>
                emissionFactorTableId > 10
                    ? GhgChart.BackgroundColors.scope3SecondCategories[index]
                    : GhgChart.BackgroundColors.scope3FirstCategories[index]
            const otherData: BarData = {
                label: 'その他',
                data: Array.from({ length: 12 }, () => 0),
                backgroundColor: backgroundColor(9),
                stack,
            }
            let i = 0
            for (const [key, values] of Object.entries(newScopeData)) {
                if (i > 8) {
                    otherData.data = sumOfMatrix([otherData.data, values])
                } else {
                    const label = key.split('--')[0]
                    const index: number = +key.split('--')[1]
                    dataset.unshift({
                        label: label,
                        data: values,
                        backgroundColor: backgroundColor(index),
                        stack: stack,
                    })
                }
                i++
            }
            const showOther = Boolean(otherData.data.reduce((a, b) => a + b, 0))
            if (showOther) dataset.unshift(otherData)
            return dataset
        }

        const scope3CategorySites = function (
            resData: AnnualOrganizationSummary | AnnualSiteSummary,
            categoryId: Category['id'],
        ): GhgStackedBarProps['datasets'] {
            let siteData: { [key: string]: Array<number> } = {}

            for (let i = startMonth; i < 12 + startMonth; i++) {
                const monthlySummary = findByMonth(resData.months, moduloFromOne(i)) as MonthlyOrganizationSummary
                if (monthlySummary) {
                    for (const site of monthlySummary.sites) {
                        const key = `${site.id}-${site.name}`
                        if (!siteData[key]) siteData[key] = new Array(12).fill(0)

                        const category = site.scopes.find((s) => s.id == 3)?.categories.find((c) => c.id == categoryId)
                        if (category) {
                            siteData[key][i - startMonth] = category.quantity
                        }
                    }
                }
            }

            const dataset = []
            let index = 0
            for (const [key, data] of Object.entries(siteData)) {
                dataset.unshift({
                    label: key.split('-')[1],
                    data: data,
                    backgroundColor: GhgChart.BackgroundColors.sites[index],
                    stack: 'hogehoge',
                })
                index++
            }
            return dataset
        }

        const scope3CategoryHighestEmissionFactors = function (
            resData: AnnualOrganizationSummary | AnnualSiteSummary,
            categoryId: Category['id'],
        ): GhgStackedBarProps['datasets'] {
            let eFData: { [key: string]: Array<number> } = {}
            const othersData = new Array(12).fill(0)

            for (let i = startMonth; i < 12 + startMonth; i++) {
                const category = (findByMonth(resData.months, moduloFromOne(i)) as Summary.Monthly)?.scopes
                    .find((s) => s.id == 3)
                    ?.categories.find((c) => c.id == categoryId)
                if (category) {
                    const { highestEmissionFactors, quantity } = category
                    if (highestEmissionFactors) {
                        for (const eF of highestEmissionFactors) {
                            const key = `${eF.id}-${eF.name}`
                            if (!eFData[key]) eFData[key] = new Array(12).fill(0)
                            eFData[key][i - startMonth] = eF.quantity
                        }
                        const highestEmissionFactorsTotal = sum(highestEmissionFactors.map((eF) => eF.quantity))
                        othersData[i - startMonth] = round(quantity - highestEmissionFactorsTotal)
                    }
                }
            }

            const dataset = []
            let index = 0
            for (const [key, data] of Object.entries(eFData).sort((a, b) => sum(b[1]) - sum(a[1]))) {
                dataset.push({
                    label: key.split('-')[1],
                    data: data,
                    backgroundColor: GhgChart.BackgroundColors.scope3Categories[index],
                    stack: 'hogehoge',
                })
                index++
            }

            dataset.push({
                label: 'その他',
                data: othersData,
                backgroundColor: GhgChart.BackgroundColors.other,
                stack: 'hogehoge',
            })

            return dataset
        }

        const sites = function (
            resData: AnnualOrganizationSummary,
            scopeNumbers = [1, 2, 3],
        ): GhgStackedBarProps['datasets'] {
            let siteData: { [key: string]: Array<number> } = {}
            const otherSites = {
                label: 'その他',
                data: [],
                backgroundColor: GhgChart.BackgroundColors.sites[GhgChart.BackgroundColors.sites.length - 1],
                stack: 'hogehoge',
            }

            for (let i = startMonth; i < 12 + startMonth; i++) {
                const monthlySummaryData: SiteSummaryItem[] = []
                const monthlySummary = findByMonth(resData.months, moduloFromOne(i)) as MonthlyOrganizationSummary
                monthlySummary?.sites.forEach((m) => {
                    monthlySummaryData.push(m)
                })
                if (monthlySummary) {
                    for (const site of monthlySummaryData) {
                        const key = `${site.id}-${site.name}`
                        if (!siteData[key]) siteData[key] = new Array(12).fill(0)

                        siteData[key][i - startMonth] = site.scopes
                            .filter((scope) => scopeNumbers.includes(scope.id))
                            .map((scope) => scope.quantity)
                            .reduce((a, b) => a + b, 0)
                    }
                }
            }

            const newSiteData = handleData(siteData)
            const convertToArr = Object.entries(newSiteData).sort((a, b) => {
                const aNumber = +a[0].split('-')[0]
                const bNumber = +b[0].split('-')[0]
                return aNumber - bNumber
            })

            const sortData = convertToArr.sort((a, b) => {
                const aQuantity = a[1].reduce((x, y) => x + y, 0)
                const bQuantity = b[1].reduce((x, y) => x + y, 0)
                return bQuantity - aQuantity
            })

            const dataset = []
            const otherData: number[][] = []
            let index = 0
            for (const [key, data] of sortData) {
                if (index < 9) {
                    dataset.unshift({
                        label: key.split('-')[1],
                        data: data,
                        backgroundColor: GhgChart.BackgroundColors.sites[index],
                        stack: 'hogehoge',
                    })
                } else {
                    otherData.push(data)
                }
                index++
            }
            if (otherData.length) {
                const data = sumOfMatrix(otherData)
                dataset.unshift({
                    ...otherSites,
                    data,
                })
            }
            return dataset
        }
        return {
            scopes,
            scopesCompare,
            scope1or2,
            scope1or2Detail,
            scope3Categories,
            scope3CategoriesDetails,
            scope3CategorySites,
            scope3CategoryHighestEmissionFactors,
            sites,
            compare,
        }
    }

    // =================================================================================
    // 円グラフ
    // =================================================================================

    type LabelsDataset = Pick<GhgDoughnutProps, 'labels' | 'dataset'>

    export namespace Doughnut {
        export const scopes = function (
            resData:
                | AnnualSiteSummary
                | AnnualOrganizationSummary
                | HalfYearOrganizationSummary
                | QuarterlyOrganizationSummary
                | MonthlyOrganizationSummary
                | MonthlySiteSummary,
            scopeNumbers = [1, 2, 3],
        ): LabelsDataset {
            let scopes = resData.scopes.filter((s) => scopeNumbers.includes(s.id))
            let data = new Array(scopeNumbers.length).fill(0)

            const labels = scopeNumbers.map((num) => `Scope ${num}`)
            for (const scopeNumber of scopeNumbers) {
                const scope = scopes.find((s) => s.id == scopeNumber)
                if (scope) data[scopeNumbers.indexOf(scopeNumber)] = scope.quantity
            }

            const dataset = {
                label: 'My First Dataset',
                data,
                backgroundColor: GhgChart.BackgroundColors.allScopes,
            }

            return { labels, dataset }
        }
        export const scopes1and2 = function (
            resData: AllOrganizationSiteSummary,
            scopeNumbers = [1, 2],
        ): LabelsDataset {
            let scopes = resData.scopes.filter((s) => scopeNumbers.includes(s.id))
            // flatten array scope -> categories
            const newScopes = scopes
                .map((e) => e.categories?.map((c) => c.highestEmissionFactorTables).flat())
                .flat() as unknown as EmissionFactorTableSummaryItem[]
            // sum categoryEmissionFactorTableId [4,5,6] -> newItem
            const newItem: EmissionFactorTableSummaryItem = { quantity: 0, id: 0, name: '' }
            for (let i = newScopes.length - 1; i >= 0; i--) {
                if (newScopes[i] && [4, 5, 6].includes(newScopes[i].categoryEmissionFactorTableId as number)) {
                    newItem.id = newScopes[i].id
                    newItem.name = newScopes[i].name
                    newItem.categoryEmissionFactorTableId = newScopes[i].categoryEmissionFactorTableId
                    newItem.quantity = newItem.quantity + newScopes[i].quantity
                    newScopes.splice(i, 1)
                }
            }
            newScopes.unshift(newItem)
            const allLabels: Array<{ label: string; id: number }> = [0, 1, 2, 3, 101, 102].map((num) => checkLabel(num))
            const allQuantity = allLabels.map((b, i) => {
                let quantity
                if ([1, 2, 3, 101, 102].includes(b.id))
                    quantity = newScopes.find((n) => n.categoryEmissionFactorTableId === b.id)?.quantity
                else quantity = newScopes[i]?.quantity
                return {
                    label: b.label,
                    quantity: quantity || 0,
                }
            })

            const labels = allQuantity.map((q) => q.label) || []
            const data = allQuantity.map((q) => q.quantity) || []
            const dataset = {
                label: 'My First Dataset',
                data,
                backgroundColor: GhgChart.BackgroundColors.scope3Categories.reverse(),
            }

            return { labels, dataset }
        }

        export const scope1or2 = function (
            resData: AllOrganizationSiteSummary,
            id: number,
            emissionFactorTableNodes: EmissionFactorTableNode[],
        ): LabelsDataset {
            let data: number[] = []
            let dataSorter = []
            const labels: string[] = []
            let backgroundColors: string[] = []

            const scope = resData.scopes.find((s) => s.id === id) as unknown as ScopeSummaryItem
            if (scope && scope.categories) {
                const emissionFactorTableSorter = scope.categories[0]
                    .highestEmissionFactorTables as EmissionFactorTableSummaryItem[]
                for (const category of emissionFactorTableSorter) {
                    if (category) {
                        const node = emissionFactorTableNodes.find(
                            (node) => node.id === category.categoryEmissionFactorTableId,
                        )
                        const index = emissionFactorTableNodes.findIndex(
                            (node) => node.id === category.categoryEmissionFactorTableId,
                        )
                        dataSorter.push({
                            id: index,
                            label: `${node?.parent ? `${node.parent} > ` : ''} ${node?.name || ''}`,
                            quantity: category.quantity,
                        })
                    }
                }
                dataSorter
                    .sort((a, b) => a.id - b.id)
                    .forEach((d) => {
                        data.push(d.quantity)
                        labels.push(d.label)
                    })

                backgroundColors =
                    id === 1
                        ? GhgChart.BackgroundColors.scope1Categories.slice(0, data.length)
                        : GhgChart.BackgroundColors.scope2Categories.slice(0, data.length)
            }
            const dataset = {
                label: 'My First Dataset',
                data: data,
                backgroundColor: backgroundColors,
            }
            return { labels, dataset }
        }

        export const scope1or2Detail = (
            resData: YearSummaryItem[],
            id: number,
            year: number,
            emissionFactorTableId: number,
            categoryEmissionFactorTableId: number,
        ) => {
            let data: number[] = []
            let labels: string[] = []
            const otherPoint = 9
            const other: { label: string; quantity: number; isVisible: boolean } = {
                label: 'その他',
                quantity: 0,
                isVisible: false,
            }

            const scopeCategories = resData
                .find((item) => item?.year === year)
                ?.scopes.find((s) => s.id === id)
                ?.categories[0].emissionFactorTables?.find(
                    (eF) =>
                        eF.id === emissionFactorTableId &&
                        eF.categoryEmissionFactorTableId === categoryEmissionFactorTableId,
                )
                ?.emissionFactors?.filter((eF) => eF.quantity)

            const emissionFactors: EmissionFactorSummaryItem[] = []

            if (scopeCategories) {
                scopeCategories.forEach((eF) => {
                    const emissionFactorIndex = emissionFactors.findIndex((e) => e.name === eF.name)
                    if (emissionFactorIndex === -1) {
                        emissionFactors.push(eF)
                    } else {
                        const eFItem = emissionFactors[emissionFactorIndex]
                        emissionFactors[emissionFactorIndex] = {
                            ...eFItem,
                            quantity: eFItem.quantity + eF.quantity,
                        }
                    }
                })
            }

            emissionFactors
                .sort((a, b) => a.id - b.id)
                .forEach((category, i) => {
                    if (category) {
                        if (i < otherPoint) {
                            data.push(category.quantity)
                            labels.push(category.name)
                        } else {
                            other.quantity = other.quantity + category.quantity
                            other.isVisible = true
                        }
                    }
                })

            const backgroundColor =
                id === 1
                    ? GhgChart.BackgroundColors.scope1CategoriesDetail
                    : GhgChart.BackgroundColors.scope2CategoriesDetail

            const dataset = {
                label: 'Detail Scope Dataset',
                data: other.isVisible ? [...data, other.quantity] : data,
                backgroundColor,
            }
            labels = other.isVisible ? [...labels, other.label] : labels
            return { labels, dataset }
        }

        export const detailsScopeData = (
            resData: MonthSummaryItem[],
            id: number,
            startMonth: number,
            emissionFactorTableId: number,
            categoryEmissionFactorTableId: number,
            mode: DMode,
        ) => {
            let data: number[] = []
            let labels: string[] = []
            const otherPoint = 9
            const other: { label: string; quantity: number; isVisible: boolean } = {
                label: 'その他',
                quantity: 0,
                isVisible: false,
            }

            const options = getHalfQuaterOptions(startMonth)

            const newData: EmissionFactorSummaryItem[] = []
            const findDataByCondition = resData.filter((res, i) => {
                const month = +res.month.split('-')[1]
                if (mode.mode === 'month') {
                    return month === mode.value
                }
                if (mode.mode === 'half') {
                    if (mode.value === 1) return options.half.first.includes(month)
                    return options.half.second.includes(month)
                }
                if (mode.mode === 'quater') {
                    if (mode.value === 1) return options.quarter.q1.includes(month)
                    if (mode.value === 2) return options.quarter.q2.includes(month)
                    if (mode.value === 3) return options.quarter.q3.includes(month)
                    return options.quarter.q4.includes(month)
                }
            })

            const scopes = findDataByCondition
                .map((r) => r.scopes)
                .flat()
                .filter((s) => s.id === id)

            const eFList = scopes
                .map((s) => s.categories)
                .flat()
                .map((c) => c.emissionFactorTables)
                .flat()
                .find(
                    (eFTable) =>
                        eFTable?.id === emissionFactorTableId &&
                        eFTable.categoryEmissionFactorTableId === categoryEmissionFactorTableId,
                )?.emissionFactors

            eFList?.forEach((eF) => {
                if (eF) {
                    const index = newData.findIndex((d) => d.id === eF.id)
                    if (index !== -1) {
                        newData[index].quantity = (newData[index]?.quantity || 0) + eF.quantity
                    } else {
                        newData.push(eF)
                    }
                }
            })

            newData.forEach((eF, i) => {
                if (i < otherPoint) {
                    data.push(eF.quantity)
                    labels.push(eF.name)
                } else {
                    other.quantity = other.quantity + eF.quantity
                    other.isVisible = true
                }
            })

            labels = other.isVisible ? [...labels, other.label] : labels
            const dataset = {
                label: 'My First Dataset',
                data: other.isVisible ? [...data, other.quantity] : data,
                backgroundColor:
                    id === 1
                        ? GhgChart.BackgroundColors.scope1CategoriesDetail
                        : GhgChart.BackgroundColors.scope2CategoriesDetail,
            }

            return { labels, dataset }
        }

        export const detailsScope3Data = (
            resData: MonthSummaryItem[],
            startMonth: number,
            categoryEmissionFactorTableId: number,
            mode: DMode,
        ) => {
            let data: number[] = []
            let labels: string[] = []
            const otherPoint = 9
            const other: { label: string; quantity: number; isVisible: boolean } = {
                label: 'その他',
                quantity: 0,
                isVisible: false,
            }
            const options = getHalfQuaterOptions(startMonth)
            const newData: EmissionFactorSummaryItem[] = []
            const findDataByCondition = resData.filter((res, i) => {
                const month = +res.month.split('-')[1]
                if (mode.mode === 'month') {
                    return month === mode.value
                }
                if (mode.mode === 'half') {
                    if (mode.value === 1) return options.half.first.includes(month)
                    return options.half.second.includes(month)
                }
                if (mode.mode === 'quater') {
                    if (mode.value === 1) return options.quarter.q1.includes(month)
                    if (mode.value === 2) return options.quarter.q2.includes(month)
                    if (mode.value === 3) return options.quarter.q3.includes(month)
                    return options.quarter.q4.includes(month)
                }
            })

            const scopes = findDataByCondition
                .map((r) => r.scopes)
                .flat()
                .filter((s) => s.id === 3)

            const eFList = scopes
                .map((s) => s.categories)
                .flat()
                .filter((c) => c.id === categoryEmissionFactorTableId)
                .map((c) => c.emissionFactorTables)
                .flat()
                .map((eFT) => eFT?.emissionFactors)
                .flat()

            eFList?.forEach((eF) => {
                if (eF) {
                    const index = newData.findIndex((d) => d.id === eF.id)
                    if (index !== -1) {
                        newData[index].quantity = (newData[index]?.quantity || 0) + eF.quantity
                    } else {
                        newData.push(eF)
                    }
                }
            })

            newData.forEach((eF, i) => {
                if (i < otherPoint) {
                    data.push(eF.quantity)
                    labels.push(eF.name)
                } else {
                    other.quantity = other.quantity + eF.quantity
                    other.isVisible = true
                }
            })

            labels = other.isVisible ? [...labels, other.label] : labels
            const dataset = {
                label: 'My First Dataset',
                data: other.isVisible ? [...data, other.quantity] : data,
                backgroundColor:
                    categoryEmissionFactorTableId > 10
                        ? GhgChart.BackgroundColors.scope3SecondCategories
                        : GhgChart.BackgroundColors.scope3FirstCategories,
            }

            return { labels, dataset }
        }

        export const scope3Categories = function (resData: AllOrganizationSiteSummary): LabelsDataset {
            let data = []
            const labels = []
            let backgroundColors: string[] = []

            const scope3 = resData.scopes?.find((s) => s.id == 3)
            if (scope3 && scope3.categories) {
                for (const category of scope3.categories) {
                    if (category) {
                        data.push(category.quantity)
                        labels.push(`${category.id - 2}. ${category.name.split('：')[1]}`)
                        backgroundColors.push(GhgChart.BackgroundColors.scope3Categories[category.id - 3])
                    }
                }
            }

            const dataset = {
                label: 'My First Dataset',
                data: data,
                backgroundColor: backgroundColors,
            }

            return { labels, dataset }
        }

        export const scope3CategoryDetails = function (
            resData: YearSummaryItem[],
            id: number,
            year: number,
            categoryEmissionFactorTableId: number,
        ): LabelsDataset {
            let data: number[] = []
            let labels: string[] = []
            let backgroundColors = GhgChart.BackgroundColors.scope3Categories
            const otherPoint = 9
            const other: { label: string; quantity: number; isVisible: boolean } = {
                label: 'その他',
                quantity: 0,
                isVisible: false,
            }

            let scopeCategories = resData
                .find((item) => item?.year === year)
                ?.scopes.find((s) => s.id === id)
                ?.categories.find((eF) => eF.id === categoryEmissionFactorTableId)
                ?.emissionFactorTables?.map((eF) => eF.emissionFactors)
                .flat()
                .filter((eF) => eF?.quantity)
                .sort((a, b) => {
                    if (a?.id && b?.id) {
                        return a.id - b.id
                    }
                    return 0
                })
            if (scopeCategories) {
                scopeCategories = uniqForObject(scopeCategories)
                scopeCategories.forEach((category, i) => {
                    if (i < otherPoint) {
                        data.push(category?.quantity as number)
                        labels.push(category?.name as string)
                    } else {
                        other.quantity = other.quantity + (category?.quantity as number) || 0
                        other.isVisible = true
                    }
                })
                backgroundColors =
                    categoryEmissionFactorTableId > 10
                        ? GhgChart.BackgroundColors.scope3SecondCategories
                        : GhgChart.BackgroundColors.scope3FirstCategories
            }

            const dataset = {
                label: 'Detail Scope Dataset',
                data: other.isVisible ? [...data, other.quantity] : data,
                backgroundColor: backgroundColors,
            }
            labels = other.isVisible ? [...labels, other.label] : labels
            return { labels, dataset }
        }

        export const scope3CategoryHighestEmissionFactors = function (
            resData: AllOrganizationSiteSummary,
            categoryId: Category['id'],
        ): LabelsDataset {
            let data = []
            const labels = []

            const scope3 = resData.scopes.find((s) => s.id == 3)
            const category = scope3?.categories.find((c) => c.id == categoryId)
            const total = category?.quantity
            const highestEmissionFactors = category?.highestEmissionFactors

            if (highestEmissionFactors) {
                for (let i = 0; i < highestEmissionFactors.length; i++) {
                    const emissionFactor = highestEmissionFactors[i]
                    data.push(emissionFactor.quantity)
                    labels.push(`${emissionFactor.name}`)
                }
            }

            const backgroundColors = GhgChart.BackgroundColors.scope3Categories.slice(0, data.length)

            if (total && data.length == 10) {
                const other = total - sum(data)
                data.push(other)
                labels.push('その他')
                backgroundColors.push(GhgChart.BackgroundColors.other)
            }

            const dataset = {
                label: 'My First Dataset',
                data: data,
                backgroundColor: backgroundColors,
            }

            return { labels, dataset }
        }

        export const scope3CategorySites = function (
            resData: AnnualOrganizationSummary | MonthlyOrganizationSummary,
            categoryId: Category['id'],
        ): LabelsDataset {
            const labels: Array<string> = []
            const data: Array<Summary.CategorySummary['quantity']> = []

            const sites = resData.sites
            sites.forEach((site) => {
                const categoryData = site.scopes.find((s) => s.id == 3)?.categories.find((c) => c.id == categoryId)
                if (categoryData) {
                    labels.push(site.name)
                    data.push(categoryData.quantity)
                }
            })

            const dataset = {
                label: 'My First Dataset',
                data,
                backgroundColor: GhgChart.BackgroundColors.sites,
            }
            return { labels, dataset }
        }

        export const sites = function (
            resData: AnnualOrganizationSummary | MonthlyOrganizationSummary,
            scopeNumbers = [1, 2, 3],
        ): LabelsDataset {
            const otherSites: SiteSummaryItem[] = []
            const sites: SiteSummaryItem[] = []
            const descSites = resData.sites.sort((a, b) => b.quantity - a.quantity)
            descSites.forEach((site, i) => {
                if (i < 9) sites.push(site)
                else {
                    otherSites.push(site)
                }
            })
            const data = sites.map((site) => {
                return sum(
                    site.scopes.filter((scope) => scopeNumbers.includes(scope.id)).map((scope) => scope.quantity),
                )
            })
            const otherData = otherSites
                .map((site) => {
                    return sum(
                        site.scopes.filter((scope) => scopeNumbers.includes(scope.id)).map((scope) => scope.quantity),
                    )
                })
                .reduce((a, b) => a + b, 0)
            let labels = sites.map((s) => s.name)

            if (resData.sites.length > 9) {
                data.push(otherData)
                labels.push('その他')
            }

            const dataset = {
                label: 'My First Dataset',
                data: sites.length < 10 ? data : [...data, otherData],
                backgroundColor: GhgChart.BackgroundColors.sites,
            }
            return { labels, dataset }
        }
    }
}

export default ResponseToChartData
