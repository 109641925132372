import _ from 'lodash'
import {safeNaN} from "@/utils/numHelper";

export function uniqForObject<T>(array: T[]): T[] {
    const result: T[] = []
    for (const item of array) {
        const found = result.some((value) => _.isEqual(value, item))
        if (!found) {
            result.push(item)
        }
    }
    return result
}

export function getSumOfMatrix(array: number[][]) {
    const result = array.reduce((r, a) => {
        a.forEach((b, i) => {
            if (isNaN(r[i]) && isNaN(b)) {
                r[i] = NaN
            } else {
                r[i] = safeNaN(r[i]) + safeNaN(b)
            }
        })
        return r
    }, [])
    return result
}

export function sliceHelper<T>(array: T[], breakPoint: number): { arrBeforeBreakPoint: T[]; arrAfterBreakPoint: T[] } {
    const arrBeforeBreakPoint: T[] = array.slice(0, breakPoint)
    const arrAfterBreakPoint: T[] = array.slice(breakPoint)
    return { arrBeforeBreakPoint, arrAfterBreakPoint }
}

export function filterByUniqueId<T>(arr: T[]): T[] {
    const uniqueIds = new Set() // create an empty set to store unique ids
    const filteredArr = arr.filter((item) => {
        if (!uniqueIds.has(item.id)) {
            // if the id is not already in the set
            uniqueIds.add(item.id) // add it to the set
            return true // include the item in the filtered array
        }
        return false // exclude the item from the filtered array
    })
    return filteredArr
}
